import React from "react";
import "./furtherButton.css";

const FurtherButton = (props) => {
  return (
    <div className="further-btn">
      <button onClick={props.onFurtherButtonClick}>mehr</button>
    </div>
  );
};

export default FurtherButton;
