import React, { Component } from "react";

import Header from "./header/header";
import BigArticle from "./overview/bigArticle/bigArticle";
import SmallArticleSection from "./overview/smallArticle/smallArticleSection";
import FurtherButton from "./overview/furtherButton/furtherbutton";
import Footer from "./footer/footer";
import Article from "../article/article";

import "./mobile.css";

import { readDocumentsFromCollection } from "../../services/firebase-crud";
import { Switch, Route } from "react-router-dom";
import uniqid from "uniqid";
import Subscribe from "../additional-sites/subscribe";
import Inserieren from "../additional-sites/inserieren";
import Impressum from "../additional-sites/impressum";

class Mobile extends Component {
  state = {
    sections: [[], [], [], []],
    renderCycles: [0, 2],
    renderFurtherButton: false,
  };

  async componentDidMount() {
    const sections = [[], [], [], []];
    const articles = await readDocumentsFromCollection("articles");
    articles.reverse();
    console.log(articles);

    articles.forEach((a, i) => {
      if (i === 0) {
        sections[0] = a;
      } else if (i < 4) {
        sections[1].push(a);
      } else if (i === 5) {
        sections[2] = a;
      } else if (i < 8) {
        sections[3].push(a);
      }
    });
    const renderFurtherButton = articles.length > 8;
    this.setState({ sections, renderFurtherButton, articles });
  }

  handleFurtherButtonClick = () => {
    const renderCycles = this.state.renderCycles;
    const len = renderCycles.length;
    const currentCycleNum = renderCycles[len - 1] + 2;
    renderCycles.push(currentCycleNum);

    const { articles } = this.state;
    let currentAmountOfRenderedArticles = 8;
    for (let i = 4; i < this.state.sections; i += 2) {
      currentAmountOfRenderedArticles += 4;
    }
    articles.splice(0, currentAmountOfRenderedArticles);
    const { sections } = this.state;
    const sectionAmount = sections.length;

    sections.push([]);
    sections.push([]);
    articles.forEach((a, i) => {
      if (i === 0) {
        sections[sectionAmount] = a;
      } else if (i < 6) {
        sections[sectionAmount + 1].push(a);
      }
    });

    const renderFurtherButton =
      this.state.articles.length > currentAmountOfRenderedArticles + 4;

    this.setState({
      sections,
      renderCycles,
      renderFurtherButton,
    });
  };
  render() {
    const { sections, renderCycles, renderFurtherButton } = this.state;
    return (
      <>
        <Header />
        <Switch>
          <Route path="/" exact>
            {sections[0].length === 0 && (
              <div className="mobile-loading-placeholder">
                <p>Artikel werden geladen...</p>
              </div>
            )}
            {renderCycles.map((i) => {
              return (
                <React.Fragment key={uniqid()}>
                  <BigArticle article={sections[i]} />
                  <SmallArticleSection articles={sections[i + 1]} />
                </React.Fragment>
              );
            })}
            {renderFurtherButton && (
              <FurtherButton onClick={this.handleFurtherButtonClick} />
            )}
          </Route>
          <Route path="/404-not-found" exact>
            <div>Der gewünschte Artikel konnte nicht gefunden werden.</div>
          </Route>
          <Route path="/magazin-abonnieren" exact>
            <Subscribe />
          </Route>
          <Route path="/inserieren" exact>
            <Inserieren />
          </Route>
          <Route path="/impressum" exact>
            <Impressum />
          </Route>
          <Route path="/:article" exact>
            <Article />
          </Route>
        </Switch>

        <Footer />
      </>
    );
  }
}

export default Mobile;
