import React from "react";
import { ReactComponent as SpLogo } from "../../../images/SP.svg";
import "./footer.css";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-triangle"></div>
      <div className="footer-rect">
        <div className="footer-container overview-footer">
          <ul className="footer-link-list">
            <li>
              <Link to="/inserieren">Inserieren</Link>
            </li>
            <li>
              <Link to="/magazin-abonnieren">Links Magazin abonnieren</Link>{" "}
            </li>
            <li>
              <a
                href="https://www.sp-ps.ch/de/mitmachen/werden-sie-mitglied-der-sp"
                target="_blank"
                rel="noreferrer"
              >
                SP Mitglied werden
              </a>
            </li>
            <li>
              <a
                href="https://www.sp-sg.ch/kontakt/"
                target="_blank"
                rel="noreferrer"
              >
                Kontakt
              </a>
            </li>

            <li>
              <Link to="/impressum">Impressum</Link>
            </li>
          </ul>
          <a href="https://www.sp-sg.ch/" target="_blank" rel="noreferrer">
            <SpLogo className="sp-logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
