import React, { Component } from "react";
import Article1Section from "./articleType1/article1Section";
import Article2Section from "./articleType2/article2Section";
import ArticleType3 from "./articleType3/articleType3";
import FurtherButton from "./furtherButton/furtherbutton";

import "./overview.css";

import uniqid from "uniqid";

class Overview extends Component {
  state = {
    articles: this.props.articles,
    sections: [[], [], [], []],
    renderCycles: [2],
    renderFurtherButton: false,
  };

  componentDidMount() {
    const sections = [...this.state.sections];
    let articles = [...this.state.articles];

    // insert newsLetterTile as article type
    articles = [
      ...articles.slice(0, 8),
      { type: "newsletterTile" },
      ...articles.slice(8, articles.length),
    ];

    articles.forEach((a, i) => {
      if (i < 3) {
        sections[0].push(a);
      } else if (i < 9) {
        sections[1].push(a);
      } else if (i < 10) {
        sections[2] = a;
      } else if (i < 16) {
        sections[3].push(a);
      }
    });

    const renderFurtherButton = articles.length > 16;
    this.setState({ sections, renderFurtherButton });
  }

  handleFurtherButtonClick = () => {
    const renderCycles = [...this.state.renderCycles];
    const len = renderCycles.length;
    const currentRenderCycleNum = renderCycles[len - 1];
    renderCycles.push(currentRenderCycleNum + 2);

    const articles = [...this.state.articles];
    let currentAmountOfRenderedArticles = 16;
    for (let i = 4; i < this.state.sections.length; i += 2) {
      currentAmountOfRenderedArticles += 7;
    }

    articles.splice(0, currentAmountOfRenderedArticles);

    const sections = [...this.state.sections];
    const sectionAmount = sections.length;
    sections.push([]);
    sections.push([]);
    articles.forEach((a, i) => {
      if (i === 0) {
        sections[sectionAmount] = a;
      } else if (i <= 6) {
        sections[sectionAmount + 1].push(a);
      }
    });
    const renderFurtherButton =
      this.state.articles.length > currentAmountOfRenderedArticles + 7;
    this.setState({ sections, renderCycles, renderFurtherButton });
  };

  render() {
    const { sections, renderFurtherButton } = this.state;
    const { renderCycles } = this.state;

    return (
      <div>
        <Article1Section articles={sections[0]} />

        <Article2Section articles={sections[1]} />

        {renderCycles.map((i) => {
          return (
            <React.Fragment key={uniqid()}>
              <ArticleType3 article={sections[i]} />
              <Article2Section articles={sections[i + 1]} />
            </React.Fragment>
          );
        })}

        {renderFurtherButton && (
          <FurtherButton onFurtherButtonClick={this.handleFurtherButtonClick} />
        )}
      </div>
    );
  }
}

export default Overview;
