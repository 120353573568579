import React, { Component } from "react";

import "./articleType3.css";

import { Link } from "react-router-dom";
import uniqid from "uniqid";

class ArticleType3 extends Component {
  state = {
    article: {
      h2: "",
      h3: "",
      p: "",
      url: "",
      articleImageURL: "",
    },
    id: uniqid(),
  };

  componentDidMount() {
    this.setState({ article: this.props.article });
  }

  render() {
    if (!this.state.article.p) {
      return <></>;
    }
    const { articleImageURL, h2, h3, url } = this.state.article;
    const paragraphs = this.state.article.p.split("\n");

    return (
      <section>
        <Link to={"/" + url}>
          <div className="big-article hover-shadow">
            <div className="big-art-pic-container">
              <img className="big-art-pic" alt="" src={articleImageURL} />
            </div>

            <div className="big-art-text">
              <h2 className="big-h2">{h2}</h2>
              <h3 className="big-h3">{h3}</h3>

              <p className="content" id={this.state.id}>
                {paragraphs.map((p) => {
                  if (p[0] === "*" && p[p.length - 1] === "*") {
                    p = p.replaceAll("**", "");
                  }
                  return (
                    <React.Fragment key={uniqid()}>
                      {p} <br />
                    </React.Fragment>
                  );
                })}
              </p>
            </div>
          </div>
        </Link>
      </section>
    );
  }
}

export default ArticleType3;
