import React, { PureComponent } from "react";
import "./additional-site.css";

class Subscribe extends PureComponent {
  render() {
    window.scrollTo(0, 0);
    return (
      <div className="additional-site-container">
        <h1>Abonnieren</h1>
        <p>Liest Du lieber auf Papier?</p>
        <p>
          Gerne senden wir dir das LINKS kostenlos 4x jährlich per Post zu.
          Melde Dich gerne per Mail an{" "}
          <a href="mailto:info@sp-sg.ch">info@sp-sg.ch</a> und teile uns Deine
          Postadresse mit.
        </p>
        <p>
          Übrigens: Als Mitglied der SP/SG erhältst du das LINKS automatisch
          zugestellt.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.sp-ps.ch/de/mitmachen/werden-sie-mitglied-der-sp"
        >
          Zur Mitgliedschaft gehts hier.
        </a>
      </div>
    );
  }
}

export default Subscribe;
