import React from "react";
import NewsletterInput from "./newsletterInput";
import "./newsletterTile.css";

const NewsletterTile = () => {
  return (
    <div className="newsletter-tile-box">
      <h2 className="newsletter-title">Newsletter abonnieren!</h2>
      <NewsletterInput />
    </div>
  );
};

export default NewsletterTile;
