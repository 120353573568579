import React from "react";

import { Link } from "react-router-dom";

import "./card.css";

const Card = (props) => {
  const { article, ad } = props;

  if (!article && !ad) {
    return <></>;
  }

  return (
    <article className="article">
      <img src={article ? article.articleImageURL : ad.imageURL} alt="" />
      <div className="article-details">
        <h2>{article ? article.h2 : ad.title}</h2>
        {article && <h3>{article.h3}</h3>}
      </div>

      <div className="button-container">
        <Link
          to={article ? "/edit/" + article.url : "/edit/inserate/" + ad.url}
        >
          <button className="btn btn-primary">Bearbeiten</button>
        </Link>

        {article ? (
          <button
            className="btn btn-danger"
            onClick={() => props.onDelete(article.h2, article.h3, article.id)}
          >
            Löschen
          </button>
        ) : (
          <button
            className="btn btn-danger"
            onClick={() => props.onDelete(ad.h2, ad.id)}
          >
            Löschen
          </button>
        )}
      </div>
    </article>
  );
};

export default Card;
