import "./newsletterInput.css";
import react from "react";
import { createDocument } from "../../../../services/firebase-crud";
import uniqid from "uniqid";

const mailchimp = require("@mailchimp/mailchimp_marketing");
mailchimp.setConfig({
  apiKey: "778d78cb3bb00638e76a9e353ac64661-us10",
  server: "https://links-sg.ch/",
});

const NewsletterInput = () => {
  const [mailInput, setMailInput] = react.useState("");
  const [status, setStatus] = react.useState({
    message: "",
    sucess: undefined,
    classNameInputField: "",
  });

  const validateMail = () => {
    if (!mailInput.includes("@")) {
      return false;
    }
    if (!mailInput.includes(".")) {
      return false;
    }

    return true;
  };

  const submitMail = async () => {
    try {
      await createDocument("newsletter", uniqid(), {
        mailAddress: mailInput,
        timestamp: new Date(),
      });
      return true;
    } catch (e) {
      console.log("error: ", e);
      return false;
    }
  };

  const handleClick = async () => {
    if (!validateMail()) {
      setStatus({
        message: "Mailadresse ungültig!",
        success: false,
        classNameInputField: "newsletter-input-failure",
      });

      return;
    }
    let success = await submitMail();
    if (!success) {
      setStatus({
        message: "Es ist ein Fehler aufgetreten!",
        success: false,
        classNameInputField: "newsletter-input-failure",
      });
      return;
    }

    setMailInput("");
    setStatus({
      message: "Mailadresse erfolgreich übermittelt.",
      success: true,
      classNameInputField: "newsletter-input-success",
    });

    setTimeout(() => {
      setStatus({
        message: "Mailadresse erfolgreich übermittelt.",
        success: true,
        classNameInputField: "",
      });
    }, 3000);
  };

  const handleInputChange = (s) => {
    setStatus({ message: "", success: undefined, classNameInputField: "" });
    setMailInput(s);
  };

  return (
    <div className="newsletter-input-box">
      <input
        id="newsletter-input"
        className={status.classNameInputField}
        type="text"
        placeholder=" "
        value={mailInput}
        onChange={(e) => handleInputChange(e.target.value)}
      />
      <label
        htmlFor="newsletter-input"
        className={
          status.classNameInputField === ""
            ? "newsletter-input-label"
            : status.classNameInputField === "newsletter-input-success"
            ? "newsletter-input-label newsletter-input-success"
            : "newsletter-input-label newsletter-input-failure"
        }
      >
        {status.classNameInputField === "newsletter-input-success"
          ? "Danke"
          : "E-Mail"}
      </label>
      <div className="newsletter-bottom-box">
        <button className="newsletter-button" onClick={() => handleClick()}>
          Abonnieren
        </button>
        <p className="newsletter-status-message">{status.message}</p>
      </div>
    </div>
  );
};

export default NewsletterInput;
