import React from "react";
import kantonSg from "../../../images/kanton_sg.svg";
import linksLogo from "../../../images/links-logo.svg";

import "./header.css";

const Header = () => {
  return (
    <header className="overview-header">
      <div className="header-triangle">
        <img className="sg-logo" src={kantonSg} alt="" />
      </div>

      <a className="logo-link" href="/">
        <h1 className="logo">
          <img className="logo" src={linksLogo} alt="Links" />
        </h1>
      </a>
    </header>
  );
};

export default Header;
