import React from "react";

import "./smallArticle.css";
import { Link } from "react-router-dom";

const SmallArticle = (props) => {
  const { article } = props;
  return (
    <Link className="small-article-mobile-link" to={article.url}>
      <div className="small-article-mobile">
        <img alt="" src={article.articleImageURL} />
        <div className="small-article-text-mobile">
          <h2>{article.h2}</h2>
          <p>{article.h3}</p>
        </div>
      </div>
    </Link>
  );
};

export default SmallArticle;
