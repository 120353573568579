import React from "react";
import SmallArticle from "./smallArticle";

import "./smallArticle.css";
import uniqid from "uniqid";

function SmallArticleSection(props) {
  const { articles } = props;
  if (!articles[0]) {
    return null;
  }
  return (
    <section className="small-article-section-mobile">
      {articles.map((a) => {
        return <SmallArticle article={a} key={uniqid()} />;
      })}
    </section>
  );
}

export default SmallArticleSection;
