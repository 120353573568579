import React, { PureComponent } from "react";

import "./additional-site.css";

class Impressum extends PureComponent {
  render() {
    window.scrollTo(0, 0);
    return (
      <div className="additional-site-container">
        <h2>Impressum</h2>
        <p>Klartext zur Politik im Kanton St.Gallen.</p>
        <p>
          Mitglieder-Magazin der SP des Kantons St.Gallen, <br />
          Postfach, 9001 St.Gallen, <br />
          Telefon: 071 222 45 85, <br />
          E-Mail: info@sp-sg.ch
        </p>
        <p>
          Redaktion: Karin Blöchlinger, Moritz Rohner, Ronja Stahl, Sepp
          Gähwiler, Irma Graf, Robin Fretz, Andrea Scheck, Marco Dal Molin,
          Guido Berlinger-Bolt
        </p>
        <p>Lektorat: Tako-Text</p>
        <p>Idee Web: Joel Müller</p>
        <p>Webdesign und Programmierung: Dominic Tobler</p>

        <h2>Icons</h2>

        <div className="credits-section">
          <p>
            Facebook Icon erstellt von `Freepik` --&gt;{" "}
            <a
              href="https://www.flaticon.com/free-icons/facebook"
              title="facebook icons"
              target="_blank"
              rel="noreferrer"
            >
              Flaticon
            </a>
          </p>
          <p>
            Whatsapp Icon erstellt von `Freepik` --&gt;{" "}
            <a
              href="https://www.flaticon.com/free-icons/whatsapp"
              title="whatsapp icons"
              target="_blank"
              rel="noreferrer"
            >
              Flaticon
            </a>
          </p>
          <p>
            Kopier Icon erstellt von `alkhalifi design` --&gt;{" "}
            <a
              href="https://www.flaticon.com/free-icons/copy"
              title="copy icons"
              target="_blank"
              rel="noreferrer"
            >
              Flaticon
            </a>
          </p>
          <p>
            Checkmark Icon erstellt von `Maxim Basinski Premium` --&gt;{" "}
            <a
              href="https://www.flaticon.com/free-icons/tick"
              title="tick icons"
              target="_blank"
              rel="noreferrer"
            >
              Flaticon
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Impressum;
