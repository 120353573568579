import React from "react";
import ArticleType2 from "./articleType2";
import "./articleType2.css";

import uniqid from "uniqid";
import NewsletterTile from "../newsletter/newsletterTile";

const Article2Section = (props) => {
  const articles = props.articles;

  if (!props.articles[0]) {
    return <></>;
  }

  return (
    <section className="small-article-section">
      {articles.map((a) => {
        if (a.type === "newsletterTile")
          return <NewsletterTile key={uniqid()} />;
        return <ArticleType2 key={uniqid()} article={a} />;
      })}
    </section>
  );
};

export default Article2Section;
