import React from "react";
import ArticleType1 from "./articleType1";
import "./articleType1.css";

import uniqid from "uniqid";

const Article1Section = (props) => {
  const articles = props.articles;
  return (
    <section className="article-type-1-section">
      {articles.map((a) => (
        <ArticleType1 article={a} key={uniqid()} />
      ))}
    </section>
  );
};

export default Article1Section;
