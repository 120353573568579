import React from "react";

import { Link } from "react-router-dom";

const ArticleType2 = (props) => {
  const { articleImageURL, h2, h3, url } = props.article;

  return (
    <Link to={"/" + url}>
      <div className="small-article hover-shadow">
        <img className="small-art-pic" alt="" src={articleImageURL} />
        <div className="small-art-text">
          <h2 className="small-h2">{h2}</h2>
          <p className="small-p">{h3}</p>
        </div>
      </div>
    </Link>
  );
};

export default ArticleType2;
