import React, { Component } from "react";
import uniqid from "uniqid";
/*
import {
  createDocument,
  readDocumentsFromCollection,
  readDocumentByUrl,
  createFile,
  updateDocument,
} from "../../../services/firebase-crud";
*/

import {
  createDocument,
  readDocumentsFromCollection,
  readDocumentByUrl,
  createFile,
  updateDocument,
} from "../../../../../services/firebase-crud";

import { withRouter } from "react-router-dom";

import "./articleForm.css";

class ArticleForm extends Component {
  state = {
    article: {
      new: true,
      edited: false,
      h2: "",
      h3: "",
      p: "",
      articleImageFile: "",
      articleImagePreview: "",
      articleImageURL: "",
      imageCaption: "",
      url: "",
      articleId: null,
      articleAuthor: {
        render: false,
        new: false,
        edited: false,
        name: "",
        description: "",
        authorImageFile: "",
        authorImageURL: "",
        authorImagePreview: "",
        authorId: "",
      },
    },
    authors: [],
  };

  componentDidMount = async () => {
    const currentUrl = this.props.match.params.article;

    const article = { ...this.state.article };
    const authors = await readDocumentsFromCollection("authors");
    authors.sort((a, b) => {
      let x = a.name.split(" ");
      x = x[x.length - 1];
      let y = b.name.split(" ");
      y = y[y.length - 1];

      return x > y ? 1 : -1;
    });

    if (currentUrl === "neuer-artikel") {
      article.new = true;
      article.articleId = uniqid();
    } else {
      const dbQuery = await readDocumentByUrl("articles", currentUrl);
      const articleDbEntry = dbQuery[0];

      article.h2 = articleDbEntry.h2;
      article.h3 = articleDbEntry.h3;
      article.p = articleDbEntry.p;
      article.articleImageURL = articleDbEntry.articleImageURL;
      article.imageCaption = articleDbEntry.imageCaption;
      article.articleId = articleDbEntry.articleId;
      article.url = currentUrl;
      article.new = false;

      article.articleAuthor.render = true;
      article.articleAuthor.authorId = articleDbEntry.articleAuthor;

      const currentAuthor = authors.find(
        (author) => author.authorId === article.articleAuthor.authorId
      );
      article.articleAuthor.authorImageURL = currentAuthor.authorImageURL;
      article.articleAuthor.description = currentAuthor.description;
      article.articleAuthor.name = currentAuthor.name;
      article.articleAuthor.render = true;
      article.articleAuthor.new = false;
      article.articleAuthor.edited = false;
    }

    this.setState({
      authors,
      article,
    });
  };

  handleTitleChange = (e) => {
    const { article } = this.state;
    article.h2 = e.target.value;
    article.edited = true;
    this.setState({ article });
  };
  handleSubtitleChange = (e) => {
    const { article } = this.state;
    article.h3 = e.target.value;
    article.edited = true;
    this.setState({ article });
  };
  handleContentChange = (e) => {
    const { article } = this.state;
    article.p = e.target.value;
    article.edited = true;
    this.setState({ article });
  };
  handleImageChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      const { article } = this.state;
      if (e.target.id === "article-image-input") {
        article.articleImagePreview = reader.result;
        article.edited = true;
      } else if (e.target.id === "author-image-input") {
        article.articleAuthor.authorImagePreview = reader.result;
        article.articleAuthor.edited = true;
      } else {
        console.log(
          "error: change the id === in the function `reader.onload withing` handleImageChange"
        );
      }
      this.setState({ article });
    };

    const { article } = this.state;
    if (e.target.id === "article-image-input") {
      article.articleImageFile = e.target.files[0];
    } else if (e.target.id === "author-image-input") {
      article.articleAuthor.authorImageFile = e.target.files[0];
    } else {
      console.log("change the id === in the funtion `handleImageChange` ");
    }
    article.edited = true;
    reader.readAsDataURL(e.target.files[0]);
    this.setState({ article });
  };
  handleImageCaptionChange = (e) => {
    const { article } = this.state;
    article.edited = true;
    article.imageCaption = e.target.value;
    this.setState({ article });
  };

  handleAuthorAddClick = () => {
    const { article, authors } = this.state;
    const authorId = uniqid();
    authors.push({
      name: "Neue Person",
      description: "",
      authorImageURL: "",
      id: authorId,
      authorImageFile: "",
    });
    const { name, description, id, authorImageFile, authorImageURL } =
      this.getAuthorById(authorId);

    article.articleAuthor = {
      render: true,
      new: true,
      edited: false,
      name: name,
      description: description,
      authorId: id,
      authorImageFile: authorImageFile,
      authorImageURL: authorImageURL,
    };
    article.edited = true;

    this.setState({ article, authors });
  };
  handleAuthorDropdownChange = (e) => {
    const { article } = this.state;
    const authorId = e.target.value;
    article.edited = true;
    if (authorId === "") {
      article.articleAuthor = {
        render: false,
        new: false,
        edited: false,
        name: "",
        description: "",
        authorImageFile: "",
        authorImageURL: "",
        authorId: "",
      };
    } else {
      const currentAuthor = this.getAuthorById(authorId);
      article.articleAuthor = {
        render: true,
        new: false,
        edited: false,
        name: currentAuthor.name,
        description: currentAuthor.description,
        authorImageFile: currentAuthor.authorImageFile,
        authorImageURL: currentAuthor.authorImageURL,
        authorId: authorId,
      };
    }
    this.setState({ article });
  };

  handleAuthorNameChange = (e) => {
    const { article } = this.state;
    article.articleAuthor.name = e.target.value;
    article.articleAuthor.edited = true;
    this.setState({ article });
  };
  handleAuthorDescriptionChange = (e) => {
    const { article } = this.state;
    article.articleAuthor.description = e.target.value;
    article.articleAuthor.edited = true;

    this.setState({ article });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    // prepare article for submission
    const currentArticle = { ...this.state.article };
    currentArticle.p = currentArticle.p.replace("\\n", "\n");
    console.log(currentArticle.p);
    delete currentArticle.edited;
    delete currentArticle.new;
    delete currentArticle.articleImagePreview;
    delete currentArticle.articleImageFile;
    currentArticle.articleAuthor = currentArticle.articleAuthor.authorId;
    const urlToArticle = currentArticle.h2
      .toLowerCase()
      .split(" ")
      .join("-")
      .replace(/[^0-9a-z-]/gi, "");
    currentArticle.url = urlToArticle;

    // prepare author for submission
    const currentAuthor = { ...this.state.article.articleAuthor };
    delete currentAuthor.render;
    delete currentAuthor.edited;
    delete currentAuthor.new;
    delete currentAuthor.authorImageFile;
    delete currentAuthor.authorImagePreview;

    if (this.state.article.articleAuthor.new) {
      let authorImageURL;
      try {
        authorImageURL = await createFile(
          "author-images",
          currentAuthor.authorId,
          this.state.article.articleAuthor.authorImageFile
        );
      } catch (e) {
        console.log(
          "Something went wrong while uploading the Author image: ",
          e
        );
      }

      currentAuthor.authorImageURL = authorImageURL;
      createDocument("authors", currentAuthor.authorId, currentAuthor);
    } else if (this.state.article.articleAuthor.edited) {
      if (this.state.article.articleAuthor.authorImageFile) {
        const url = await createFile(
          "author-images",
          currentAuthor.authorId,
          this.state.article.articleAuthor.authorImageFile
        );
        currentAuthor.authorImageURL = url;
      }
      updateDocument("authors", currentAuthor.authorId, currentAuthor);
    }

    if (this.state.article.new) {
      const url = await createFile(
        "article-images",
        currentArticle.articleId,
        this.state.article.articleImageFile
      );

      currentArticle.articleImageURL = url;

      createDocument("articles", currentArticle.articleId, currentArticle);
    } else if (this.state.article.edited) {
      if (this.state.article.articleImageFile) {
        const url = await createFile(
          "article-images",
          currentArticle.articleId,
          this.state.article.articleImageFile
        );
        currentArticle.articleImageURL = url;
      }

      delete currentArticle.articleImagePreview;

      try {
        updateDocument("articles", currentArticle.articleId, currentArticle);
      } catch (e) {
        console.log("error occure while updating article: ", e);
      }
    }

    this.props.history.replace("/edit");
  };

  getAuthorById = (id) => {
    const { authors } = this.state;
    const author = authors.find((a) => a.id === id);
    return author;
  };

  render() {
    const {
      h2,
      h3,
      p,
      articleImageURL,
      articleImagePreview,
      imageCaption,
      articleAuthor,
    } = this.state.article;
    const { authors } = this.state;

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="top-section">
            <h1>{h2 ? h2 : "Neuer Artikel"}</h1>
            <button type="submit" className="btn btn-primary">
              {this.state.article.new
                ? "Artikel erstellen"
                : "Änderungen speichern"}
            </button>
          </div>
          <div className="mb-3">
            <label htmlFor="article-title-input" className="form-label">
              Titel
            </label>
            <input
              type="text"
              className="form-control"
              id="article-title-input"
              placeholder="Titel"
              autoComplete="off"
              value={h2}
              onChange={this.handleTitleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="article-subtitle-input" className="form-label">
              Untertitel
            </label>
            <input
              type="text"
              className="form-control"
              id="article-subtitle-input"
              placeholder="Untertitel"
              autoComplete="off"
              value={h3}
              onChange={this.handleSubtitleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="article-content-input" className="form-label">
              Inhalt --&gt; <i>**steht für Parapgrahentitel**</i>
            </label>
            <textarea
              className="form-control"
              name=""
              id="article-content-input"
              rows="20"
              placeholder="Inhalt"
              autoComplete="off"
              value={p}
              onChange={this.handleContentChange}
              required
            ></textarea>
          </div>

          <div className="mb-3">
            <label htmlFor="article-image-input" className="form-label">
              Titelbild <br />
              - quadratisch <br />- möglichst klein (grosse Bilder
              beeinträchtigen die Geschwindigkeit der Website) <br />
              --&gt;
              <a
                href="https://tinypng.com/"
                target="_blank"
                rel="noreferrer"
                alt="tiny png Bildkompression"
              >
                {" "}
                tinypng.com{" "}
              </a>
              für Bildkompression
            </label>
            <input
              className="form-control"
              type="file"
              id="article-image-input"
              accept="image/*"
              onChange={this.handleImageChange}
            />
          </div>
          {(articleImagePreview || articleImageURL) && (
            <div className="mb-3 image-preview">
              <label htmlFor="article-image-preview">Vorschau:</label>
              <img
                id="article-image-preview"
                src={articleImagePreview || articleImageURL}
                alt=""
              />
            </div>
          )}

          <div className="mb-3">
            <label htmlFor="article-caption-input" className="form-label">
              Bildunterschrift
            </label>
            <input
              type="text"
              className="form-control"
              id="article-caption-input"
              placeholder="Bildunterschrift"
              autoComplete="off"
              value={imageCaption}
              onChange={this.handleImageCaptionChange}
            />
          </div>

          <fieldset className="author-fieldset">
            <legend>Autor*in</legend>

            <div className="form-author-section">
              <div className="mb-3 author-dropdown">
                <select
                  className="form-control"
                  id="article-author-dropdown"
                  onChange={this.handleAuthorDropdownChange}
                  value={articleAuthor.authorId}
                  required
                >
                  <option value="">-- Bitte auswählen --</option>
                  {authors.map((a) => (
                    <option value={a.id} key={a.id}>
                      {a.name}
                    </option>
                  ))}
                </select>
              </div>

              <button
                type="button"
                className="btn btn-primary add-new-author"
                onClick={this.handleAuthorAddClick}
              >
                Autor*in hinzufügen +
              </button>
            </div>
            <p className="info-italic">
              Änderungen werden auf alle Artikel übertragen!
            </p>

            <div className="mb-3">
              <label htmlFor="author-name-input" className="form-label">
                Vor- und Nachname
              </label>
              <input
                type="text"
                className="form-control"
                id="author-name-input"
                placeholder="Name"
                autoComplete="off"
                onChange={this.handleAuthorNameChange}
                value={articleAuthor.name}
                disabled={!articleAuthor.render}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="author-description-input" className="form-label">
                Beschreibung (optional)
              </label>
              <input
                type="text"
                className="form-control"
                id="author-description-input"
                placeholder="Beschreibung"
                autoComplete="off"
                onChange={this.handleAuthorDescriptionChange}
                value={articleAuthor.description}
                disabled={!articleAuthor.render}
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor={articleAuthor.render ? "author-image-input" : ""}
                className="form-label"
              >
                Bild Autor*in
              </label>
              <input
                className="form-control"
                type="file"
                id="author-image-input"
                accept="image/*"
                onChange={this.handleImageChange}
                disabled={!articleAuthor.render}
              />
            </div>

            {(articleAuthor.authorImagePreview ||
              articleAuthor.authorImageURL) && (
              <div className="mb-3 image-preview">
                <label>Vorschau:</label>
                <img
                  src={
                    articleAuthor.authorImagePreview ||
                    articleAuthor.authorImageURL
                  }
                  alt=""
                />
              </div>
            )}
          </fieldset>

          <button type="submit" className="btn btn-primary">
            {this.state.article.new
              ? "Artikel erstellen"
              : "Änderungen speichern"}
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(ArticleForm);
