import React, { Component } from "react";

import Desktop from "./components/desktop/desktop";
import Mobile from "./components/mobile/mobile";

import { Switch, Route } from "react-router-dom";

import "./App.css";
import Admin from "./components/admin-pages/Admin";

class App extends Component {
  state = {
    isMobile: window.matchMedia("(max-width: 600px)").matches,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    const { isMobile } = this.state;
    if (isMobile === window.matchMedia("(max-width: 600px)").matches) {
      return;
    }
    this.setState({
      isMobile: window.matchMedia("(max-width: 600px)").matches,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Switch>
        <Route path="/edit">
          <Admin />
        </Route>
        <Route path="/">{this.state.isMobile ? <Mobile /> : <Desktop />}</Route>
      </Switch>
    );
  }
}

export default App;
