import React, { Component } from "react";

import Header from "./header/header";
import Overview from "./Overview/overview";
import Footer from "./footer/footer";
import Article from "../article/article";
import { Switch, Route } from "react-router-dom";

import "./desktop.css";

import { readDocumentsFromCollection } from "../../services/firebase-crud";
import Inserieren from "../additional-sites/inserieren";
import Impressum from "../additional-sites/impressum";
import Subscribe from "../additional-sites/subscribe";

class Desktop extends Component {
  state = {
    articles: [],
  };

  async componentDidMount() {
    const articles = await readDocumentsFromCollection("articles");
    articles.reverse();
    this.setState({ articles });
  }

  render() {
    const { articles } = this.state;
    return (
      <>
        <div className="App">
          <div>
            <Header />
            <Switch>
              <Route path="/" exact>
                {articles.length < 1 && (
                  <div className="desktop-loading-placeholder">
                    <p>Artikel werden geladen...</p>
                  </div>
                )}
                {articles.length >= 1 && <Overview articles={articles} />}
              </Route>
              <Route path="/404-not-found" exact>
                <div>Der gewünschte Artikel konnte nicht gefunden werden.</div>
              </Route>
              <Route path="/magazin-abonnieren" exact>
                <Subscribe />
              </Route>
              <Route path="/inserieren" exact>
                <Inserieren />
              </Route>
              <Route path="/impressum">
                <Impressum />
              </Route>
              <Route path="/:article">
                <Article />
              </Route>
            </Switch>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Desktop;
