import React, { Component } from "react";

import "./bigArticle.css";
import { Link } from "react-router-dom";

class BigArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: props.article,
    };
  }

  render() {
    const { article } = this.state;

    if (!article.url) {
      return null;
    }
    return (
      <section className="big-article-section-mobile">
        <Link to={article.url}>
          <div
            className="article-big"
            style={{
              backgroundImage: `url(${article.articleImageURL})`,
            }}
          >
            <div className="article-big-title">
              <h2>{article.h2}</h2>
            </div>
          </div>
        </Link>
      </section>
    );
  }
}

export default BigArticle;
