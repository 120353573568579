import Login from "./components/login";

import "./Admin.css";

function Admin() {
  return (
    <div className="app">
      <Login />
    </div>
  );
}

export default Admin;
