import React, { Component } from "react";

import "./article.css";

import {
  readDocumentByAuthorId,
  readDocumentByUrl,
  readDocumentsFromCollection,
} from "../../services/firebase-crud";

import { withRouter } from "react-router-dom";
import uniqid from "uniqid";

import MetaTags from "react-meta-tags";

import { FacebookShareButton, WhatsappShareButton } from "react-share";

class Article extends Component {
  state = {
    article: {
      h2: "",
      h3: "",
      p: "",
      articleImageURL: "",
      imageCaption: "",
    },
    author: {
      authorImageURL: "",
      name: "",
      description: "",
    },
    ad: {
      imageURL: "",
      targetLink: "",
    },
    renderCheckmark: false,
  };

  async componentDidMount() {
    //console.log("props: ", this.state.props);
    const currentUrl = this.props.match.params.article;
    let article = await readDocumentByUrl("articles", currentUrl);
    if (!article[0]) {
      this.props.history.replace("/404-not-found");
      return;
    }
    article = article[0];

    let author = await readDocumentByAuthorId("authors", article.articleAuthor);
    author = author[0];

    let ads = await readDocumentsFromCollection("ads");
    ads = ads.filter((a) => a.active);
    const randomNum = Math.floor(Math.random() * (ads.length - 1 - 0 + 1) + 0);
    let ad = ads[randomNum];
    if (!ad) {
      ad = { imageURL: "", targetLink: "" };
    }

    this.setState({
      article,
      author,
      ad: { imageURL: ad.imageURL, targetLink: ad.targetLink },
    });
    window.scrollTo(0, 0);
  }

  copyClick = () => {
    navigator.clipboard.writeText(
      "https://links-sg.ch/" + this.state.article.url
    );
    this.setState({ renderCheckmark: !this.state.renderCheckmark });
  };

  render() {
    const { h2, h3, articleImageURL, imageCaption } = this.state.article;
    const { authorImageURL, name, description } = this.state.author;
    const { ad } = this.state;

    if (!h2) {
      return null;
    }

    const paragraphs = this.state.article.p.split("\n");
    if (paragraphs[0] !== "") {
      let adPlacementIndex = 5;
      if (paragraphs[2][0] === "*" && paragraphs[2][0] === "*") {
        adPlacementIndex += 1;
      }
      paragraphs.splice(adPlacementIndex, 0, "advertisement-links");
    }

    return (
      <section className="article-section">
        <MetaTags>
          <title>{h2}</title>
          <meta property="og:title" content={h2} />
          <meta property="og:image" content={articleImageURL} />
          <meta property="og:description" content={h3} />
        </MetaTags>
        <div className="side-shape">
          <div className="top-triangle"></div>
          <div className="bottom-triangle"></div>
        </div>
        <div className="article-detailed">
          <h2>{h2}</h2>
          <h3>{h3}</h3>
          <figure>
            <img src={articleImageURL} alt="" className="article-img" />
            <figcaption>{imageCaption}</figcaption>
          </figure>

          <div className="social-icons">
            <FacebookShareButton
              resetButtonStyle={false}
              url={"https://links-sg.ch/" + this.state.article.url}
              quote={h2}
              hashtag="#LinksMagazin"
              description={h3}
              className="social-icon"
            >
              <svg
                width="475"
                height="475"
                viewBox="0 0 475 475"
                fill="#282727"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M276.505 0.855935C249.762 5.03464 231.004 14.1349 215.589 30.3855C202.589 44.1288 194.51 60.1936 189.96 81.2728C188.195 89.2588 188.01 94.0876 187.638 131.603L187.267 173.204H150.587H114V215.456V257.707L150.494 257.893L186.895 258.171L187.174 366.539L187.36 475H231.004H274.648V366.354V257.707H310.864C330.829 257.707 347.079 257.614 347.079 257.429C349.586 240.528 357.851 174.412 357.572 173.854C357.387 173.483 338.629 173.204 315.878 173.204H274.555L274.834 138.568C275.112 104.766 275.205 103.931 277.341 98.5448C281.705 87.6802 290.806 81.18 304.549 79.4156C308.264 78.9513 322.471 78.487 336.214 78.487H361.008L360.822 40.6001L360.544 2.806L348.472 1.69168C328.693 -0.0726663 285.791 -0.629827 276.505 0.855935Z" />
              </svg>
            </FacebookShareButton>
            <WhatsappShareButton
              url={"https://links-sg.ch/" + this.state.article.url}
              className="social-icon"
              resetButtonStyle={false}
            >
              <svg
                width="475"
                height="475"
                viewBox="0 0 475 475"
                fill="#282727"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M210.194 1.39147C100.412 14.8692 14.0631 102.475 2.69613 211.882C1.00105 228.036 1.99816 258.757 4.79005 274.316C9.07761 298.794 16.2568 320.398 27.4243 342.498L33.0081 353.597L16.3565 414.148C7.18311 447.545 -0.195472 474.897 0.00394922 474.996C0.20337 475.194 28.1223 467.96 62.1236 459.041C111.58 446.058 124.443 442.986 126.437 443.779C127.833 444.275 134.314 447.149 140.895 450.122C162.233 459.734 184.668 466.077 208.299 469.248C224.751 471.428 253.568 471.428 269.522 469.248C321.969 461.915 368.733 438.923 405.626 402.256C424.97 383.129 437.833 365.588 449.998 341.705C460.966 320.2 468.743 296.713 473.13 271.938C475.623 257.469 475.623 214.756 473.13 199.594C468.843 173.927 462.162 153.413 450.895 130.719C418.688 66.2036 359.261 20.2207 288.167 4.86001C266.032 0.10315 233.027 -1.38337 210.194 1.39147ZM272.513 42.4194C301.13 47.6717 324.861 57.1854 348.293 72.7443C388.376 99.4025 417.791 140.728 429.457 186.512C438.032 220.207 437.334 258.46 427.662 290.866C411.908 343.191 374.517 387.291 325.459 411.076C296.942 424.95 270.618 430.995 238.611 430.995C201.918 430.995 169.512 422.175 136.308 403.148L130.724 399.976L94.031 409.589C73.8895 414.842 57.1381 419.004 57.0384 418.806C56.839 418.607 60.9271 402.949 66.2117 383.922C71.3967 364.895 75.784 348.642 75.784 347.849C75.8837 346.957 73.2912 342.002 70.1005 336.849C23.0371 260.739 35.4012 160.449 99.5151 97.0241C131.422 65.5099 171.905 46.0861 217.173 40.5364C229.438 39.0499 259.949 40.14 272.513 42.4194Z" />
                <path d="M145.012 129.18C139.328 131.955 128.759 144.343 124.372 153.46C115.697 171.298 115.896 193.893 124.87 214.705C135.838 240.372 164.655 276.841 193.072 301.022C213.014 317.968 229.666 327.977 254.095 337.491C279.122 347.203 283.908 348.293 300.46 348.293C313.522 348.293 315.616 348.095 321.599 345.815C331.57 342.05 339.048 337.392 346.427 330.157C352.21 324.509 353.407 322.725 355.301 316.779C357.594 309.247 358.99 297.256 357.894 293.094C357.395 290.913 354.503 289.13 340.345 282.093C314.42 269.21 303.95 264.751 299.563 264.751C295.874 264.751 295.275 265.147 290.888 270.895C280.817 284.075 271.544 294.283 269.151 294.877C266.26 295.571 260.576 293.49 247.514 286.949C226.276 276.445 209.026 262.273 194.169 243.345C186.092 233.038 178.814 221.344 178.814 218.57C178.814 217.479 182.603 211.632 187.189 205.686C196.163 194.092 198.756 189.731 198.756 186.461C198.756 185.371 196.761 179.92 194.468 174.271C192.075 168.722 187.289 157.226 183.799 148.802C179.412 138.298 176.42 132.451 174.227 130.171L171.036 127H160.267C151.194 127 148.701 127.396 145.012 129.18Z" />
              </svg>
            </WhatsappShareButton>
            <button
              className={
                this.state.renderCheckmark ? "social-icon no-bg" : "social-icon"
              }
              onClick={this.copyClick}
            >
              {this.state.renderCheckmark ? (
                <svg
                  width="475"
                  height="475"
                  viewBox="0 0 475 475"
                  fill="#282727"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M427.314 68.1839C417.565 71.248 417.193 71.5266 293.141 195.579L171.038 317.588L115.79 262.34C64.4422 211.178 60.0781 207 54.3212 204.307C39.5574 197.436 23.8652 199.572 12.1656 210.157C2.88026 218.514 -1.66957 231.885 0.558919 243.955C2.88026 256.398 1.3946 254.819 78.4631 331.609C145.875 398.835 150.053 402.828 155.717 405.335C162.96 408.492 172.895 409.42 179.581 407.656C190.909 404.592 186.08 409.142 330.839 264.383C480.705 114.332 472.163 123.525 474.484 110.246C477.641 91.7687 466.034 73.8479 447.371 68.1839C442.171 66.6054 432.328 66.6054 427.314 68.1839Z" />
                </svg>
              ) : (
                <svg
                  width="475"
                  height="475"
                  viewBox="0 0 475 475"
                  fill="#282727"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M135.78 1.51207C128.278 3.7989 124.292 6.31441 119.134 11.6885C109.756 21.6362 108.232 27.3532 108.232 53.3088V72.8612L85.1376 73.3185C63.8019 73.6615 61.4573 73.8902 56.0648 76.2914C45.6314 80.9794 37.7771 90.0124 34.8464 100.875C32.3845 109.793 32.3845 438.868 34.8464 447.787C37.8943 458.992 46.6865 468.826 57.3543 472.828C62.8641 474.886 68.1394 475 201.429 475H339.759L345.972 472.256C354.881 468.254 363.087 459.107 366.135 449.616C368.128 443.327 368.48 438.982 368.48 422.403V402.622L391.691 402.165C413.027 401.821 415.254 401.593 420.999 399.077C429.908 395.19 438.114 385.928 441.162 376.438C443.506 369.12 443.507 366.261 443.507 230.309C443.507 136.435 443.155 90.4697 442.334 88.2972C440.927 84.6383 359.102 4.02759 354.178 1.51207C351.482 0.139976 335.07 -0.0886973 245.741 0.0256442C164.267 0.0256442 139.415 0.368659 135.78 1.51207ZM327.684 62.4561C328.036 86.9251 328.153 88.8689 330.732 93.3283C334.835 100.875 339.993 105.906 347.144 109.336C353.709 112.538 354.061 112.538 379.968 112.881L405.993 113.338L405.759 239.228L405.407 365.232H275.869H146.331L146.097 202.296C145.862 112.652 145.979 38.6731 146.331 37.8727C146.683 36.8436 165.908 36.5006 237.066 36.5006H327.333L327.684 62.4561ZM372.583 69.6596L379.499 76.5201H372.231H364.963V69.6596C364.963 65.8863 365.08 62.7991 365.315 62.7991C365.432 62.7991 368.714 65.8863 372.583 69.6596ZM108.232 239.114C108.232 377.352 108.232 375.409 113.859 384.327C117.61 390.273 124.644 396.333 131.091 399.192L136.953 401.821L234.018 402.165L331.084 402.393L330.732 420.459L330.381 438.411H200.843H71.3046L70.9529 275.474C70.8356 185.83 70.9529 111.852 71.3046 111.051C71.6562 110.022 76.5799 109.679 90.0612 109.679H108.232V239.114Z" />
                </svg>
              )}
            </button>
          </div>

          {paragraphs.map((p) => {
            if (p === "advertisement-links") {
              if (!ad.targetLink) {
                return "";
              }
              return (
                <a
                  className="ad-container-article"
                  href={ad.targetLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Anzeige</p>
                  <img
                    className="advertisement-image"
                    src={ad.imageURL}
                    alt="inserat"
                  />
                </a>
              );
            }

            p = p.trim();
            console.log(p);
            console.log(typeof p);
            console.log(p.length);
            if (p[0] === "*" && p[p.length - 1] === "*") {
              p = p.replaceAll("**", "");
              return <h4 key={uniqid()}>{p}</h4>;
            } else if (p[0] === "-" && p[p.length - 1] === "-") {
              p = p.replaceAll("--", "");
              return (
                <p className="italic" key={uniqid()}>
                  {p}
                </p>
              );
            } else if (p.length === 0) {
              return <br key={uniqid()} />;
            }
            return <p key={uniqid()}>{p}</p>;
          })}

          <div className="author-section">
            <img src={authorImageURL} alt="" />
            <p>
              Von {name}, {description}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Article);
