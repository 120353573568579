import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import {
  createDocument,
  readDocumentByUrl,
  updateDocument,
  createFile,
} from "../../../../../services/firebase-crud";

import uniqid from "uniqid";
import "./adForm.css";

class AdForm extends Component {
  state = {
    new: true,
    edited: false,
    title: "",
    targetLink: "",
    imagePreview: "",
    imageFile: "",
    imageURL: "",
    id: "",
    url: "",
    active: true,
  };

  async componentDidMount() {
    const currentURL = this.props.match.params.inserat;
    const ad = { ...this.state };
    if (currentURL === "neu") {
      ad.new = true;
      ad.edited = false;
      ad.id = uniqid();
      this.setState({ ...ad });
    } else {
      const dbQuery = await readDocumentByUrl("ads", currentURL);
      const dbAd = dbQuery[0];
      this.setState({
        new: false,
        title: dbAd.title,
        targetLink: dbAd.targetLink,
        imageURL: dbAd.imageURL,
        id: dbAd.id,
        active: dbAd.active,
      });
    }
  }
  handleTitleChange = (e) => {
    this.setState({ title: e.target.value, edited: true });
  };
  handleTargetLinkChange = (e) => {
    this.setState({ targetLink: e.target.value, edited: true });
  };
  handleImageChange = (e) => {
    const reader = new FileReader();
    let imagePreview;
    reader.onload = () => {
      imagePreview = reader.result;
      this.setState({ imagePreview });
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState({ imageFile: e.target.files[0], edited: true });
  };
  handleSwitchChange = (e) => {
    this.setState({ active: e.target.checked, edited: true });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = { ...this.state };

    if (this.state.imageFile) {
      const imageURL = await createFile("ad-images/", data.id, data.imageFile);
      data.imageURL = imageURL;
    }
    delete data.imagePreview;
    delete data.imageFile;
    delete data.new;
    delete data.edited;

    data.url = this.state.title.toLowerCase().replaceAll(" ", "-");

    if (this.state.new) {
      createDocument("ads", data.id, data);
    } else if (this.state.edited) {
      updateDocument("ads", data.id, data);
    }

    this.props.history.replace(`/edit`);
  };

  render() {
    const { title, targetLink, imagePreview, imageURL, active } = this.state;
    return (
      <div>
        <h1>{title ? title : "Neues Inserat"}</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="mb-3">
            <label htmlFor="ad-title-input" className="form-label">
              Titel
            </label>
            <input
              type="text"
              className="form-control"
              id="ad-title-input"
              placeholder="Titel"
              autoComplete="off"
              value={title}
              onChange={this.handleTitleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="ad-link-input" className="form-label">
              Link zum Unternehmen{" "}
              <i>(wird bei Klick auf Inserat aufgerufen)</i>
            </label>
            <input
              type="text"
              className="form-control"
              id="ad-link-input"
              placeholder="Link"
              autoComplete="off"
              value={targetLink}
              onChange={this.handleTargetLinkChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="ad-image-input" className="form-label">
              Inserat
            </label>
            <input
              className="form-control"
              type="file"
              id="ad-image-input"
              accept="image/*"
              onChange={this.handleImageChange}
            />
          </div>
          {(imagePreview || imageURL) && (
            <div className="mb-3 image-preview">
              <label htmlFor="ad-image-preview">Vorschau:</label>
              <img
                id="ad-image-preview"
                src={imagePreview || imageURL}
                alt=""
                onClick={this.handleImageChange}
              />
            </div>
          )}

          <div className="mb-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input date-input"
                type="checkbox"
                role="switch"
                id="ad-switch"
                onChange={this.handleSwitchChange}
                checked={active}
              />
              <label className="form-check-label" for="ad-switch">
                {active ? "Aktiv" : "Deaktiviert"}
              </label>
            </div>
          </div>

          <button type="submit" className="btn btn-primary">
            {this.state.new ? "Inserat erstellen" : "Änderungen speichern"}
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(AdForm);
