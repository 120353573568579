import React from "react";

import "./lockInDelete.css";

const LockInDelete = (props) => {
  return (
    <div className="full-width-container">
      <div className="lock-in-delete">
        <button className="close-lock-in-delete" onClick={props.onExitLockIn}>
          x
        </button>
        <div className="lock-in-delete-text">
          <h1>Möchtest du folgenden Artikel unwiederruflich löschen?</h1>
          <h2>{props.h2}</h2>
          <h3>{props.h3}</h3>
          <div className="lock-in-btns">
            <button
              className="btn btn-danger delete"
              onClick={props.onDeleteFinally}
            >
              Löschen
            </button>
            <button
              className="btn btn-primary back"
              onClick={props.onExitLockIn}
            >
              Zurück
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockInDelete;
