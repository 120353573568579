import React, { PureComponent } from "react";
import "./additional-site.css";

class Inserieren extends PureComponent {
  render() {
    window.scrollTo(0, 0);
    return (
      <div className="additional-site-container">
        <h1>Inserieren</h1>
        <p>
          Du vertrittst mit Deinem Unternehmen oder Deinem Event die selben
          Werte wie die SP?
        </p>
        <p>
          Dann würde es uns sehr über Dein Inserat im LINKS freuen. Bei uns
          erreichst du bereits ab 300.- (print & digital) eine ökologische,
          feministische und soziale Leser*innenschaft. Mit den Werbeeinnahmen
          Refinanzieren wir das LINKS.{" "}
        </p>
        <p>
          Weitere Informationen wie die detaillierten Preise und die Reichweite
          Deiner Werbung teilen wir Dir gerne persönlich mit:{" "}
          <a href="mailto:info@sp-sg.ch">info@sp-sg.ch</a>, T: 071 222 45 85
        </p>
      </div>
    );
  }
}

export default Inserieren;
