import React from "react";
import "./articleType1.css";

import { Link } from "react-router-dom";

const ArticleType1 = (props) => {
  const { articleImageURL, h2, url } = props.article;
  return (
    <Link to={"/" + url}>
      <div
        className="article-type-1 hover-shadow"
        style={{
          backgroundImage: `url(${articleImageURL})`,
        }}
      >
        <div className="title-type-1-cont">
          <h2 className="title-type-1">{h2}</h2>
        </div>
      </div>
    </Link>
  );
};

export default ArticleType1;
