import React, { Component } from "react";

import Dashboard from "./dashboard/dashboard";
import ArticleForm from "./dashboard/Article-Form/articleForm";
import AdForm from "./dashboard/Ad-Form/adForm";
import { Route, Switch } from "react-router-dom";
import "./login.css";

import { logInUser, auth } from "../../../services/firebase-crud";
import { onAuthStateChanged } from "firebase/auth";

class Login extends Component {
  state = {
    isLoggedIn: false,
    email: "",
    password: "",
    error: "",
  };

  componentDidMount() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({ isLoggedIn: true });
      } else {
        this.setState({ isLoggedIn: false });
      }
    });
  }
  handleMailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };
  login = async (event) => {
    event.preventDefault();
    try {
      const user = await logInUser(this.state.email, this.state.password);
      this.setState({ isLoggedIn: true });
    } catch (e) {
      console.log(e.errorCode);
    }
  };

  render() {
    return (
      <>
        {!this.state.isLoggedIn ? (
          <div className="double-center">
            <>
              <h1>Links Login</h1>
              <div className="text-center card">
                <form>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email Adresse
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      onChange={this.handleMailChange}
                      value={this.state.email}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      Passwort
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Passwort"
                      onChange={this.handlePasswordChange}
                      value={this.state.password}
                    />
                  </div>
                  <button className="btn btn-primary" onClick={this.login}>
                    Einloggen
                  </button>
                </form>
              </div>{" "}
            </>
          </div>
        ) : (
          <Switch>
            <Route path="/edit" exact>
              <Dashboard />
            </Route>
            <Route path="/edit/inserate/:inserat">
              <AdForm />
            </Route>
            <Route path="/edit/:article">
              <ArticleForm />
            </Route>
          </Switch>
        )}
      </>
    );
  }
}

export default Login;
