import { storage, firestore, app } from "./firebase-config";
import {
  setDoc,
  getDocs,
  updateDoc,
  collection as createCollectionRef,
  doc,
  query,
  where,
  limit,
  deleteDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

// ------------- Operations on Firestore ---------------
async function createDocument(collection, id, document) {
  const documentRef = doc(firestore, collection, id);
  try {
    await setDoc(documentRef, document);
  } catch (e) {
    console.error("Something went wrong while creating document: ", e);
  }
}

//make sure when reading, using await or the .then() function
// eg: readDocumentsFromCollection("author").then(res => {console.log(res)})
async function readDocumentsFromCollection(collection) {
  const collectionRef = createCollectionRef(firestore, collection);
  const newQuery = query(collectionRef);
  const querySnapshot = await getDocs(newQuery);
  const data = querySnapshot.docs.map((d) => ({ ...d.data(), id: d.id }));
  return data;
}

// make sure when reading, using await or the .then() function
async function readDocumentByUrl(collection, url) {
  const collectionRef = createCollectionRef(firestore, collection);
  const q = query(collectionRef, where("url", "==", url), limit(1));
  const querySnaphot = await getDocs(q);
  const document = querySnaphot.docs.map((d) => ({ ...d.data(), id: d.id }));
  return document;
}
async function readDocumentByAuthorId(collection, authorId) {
  const collectionRef = createCollectionRef(firestore, collection);
  const q = query(collectionRef, where("authorId", "==", authorId), limit(1));
  const querySnaphot = await getDocs(q);
  const document = querySnaphot.docs.map((d) => ({ ...d.data(), id: d.id }));
  return document;
}

async function updateDocument(collection, id, updatedDocument) {
  const documentRef = doc(firestore, collection + "/" + id);
  try {
    await updateDoc(documentRef, updatedDocument);
  } catch (e) {
    console.error("Error when updating document: " + e);
  }
}

async function deleteDocument(collection, id) {
  const documentRef = doc(firestore, collection + "/" + id);
  await deleteDoc(documentRef);
}

// --------------- Operations on Storage -----------------

// to updateFile, simply call createFile with an already used id
async function createFile(folder, id, file) {
  const storageRef = ref(storage, folder + "/" + id);
  try {
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (e) {
    console.error("Something went wrong while uploading file: ", e);
  }
}
async function deleteFile(folder, id) {
  const storageRef = ref(storage, folder + "/" + id);
  deleteObject(storageRef)
    .then(() => {})
    .catch((error) => {
      console.log("an error occured while deleting file: ", error);
    });
}

// authentication
const auth = getAuth(app);
async function createUser(email, password) {
  const userCredentials = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  return userCredentials.user;
}

async function logInUser(email, password) {
  const userCredentials = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  return userCredentials.user;
}

export {
  createDocument,
  readDocumentsFromCollection,
  readDocumentByUrl,
  readDocumentByAuthorId,
  updateDocument,
  deleteDocument,
  createFile,
  deleteFile,
  createUser,
  logInUser,
  auth,
};
