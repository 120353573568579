import React, { Component } from "react";
import Card from "./card/card";
import Mail from "./newsletter/mail";

import LockInDelete from "./lockInDelete/lockInDelete";

import { Link } from "react-router-dom";

import { signOut } from "firebase/auth";
import {
  deleteDocument,
  readDocumentsFromCollection,
  deleteFile,
  auth,
} from "../../../../services/firebase-crud";
import "./dashboard.css";
import Podcast from "./Podcast/podcast";
import uniqid from "uniqid";

class Dashboard extends Component {
  state = {
    articles: [""],
    lockInDelete: { show: false, h2: "", h3: "" },
    renderSection: {
      id: "articles",
      mainButtonText: "neuer Artikel",
      mainButtonLink: "/edit/neuer-artikel",
    },
    ads: [""],
  };
  async componentDidMount() {
    const articles = await readDocumentsFromCollection("articles");
    articles.reverse();
    const ads = await readDocumentsFromCollection("ads");
    ads.reverse();
    const newsletterMails = await readDocumentsFromCollection("newsletter");
    newsletterMails.reverse();

    this.setState({
      articles,
      ads,
      newsletterMails,
    });
  }
  createRenderCycles = () => {
    const amount = this.state.renderedArticles;
    const res = [];
    for (let i = 0; i < amount; i++) {
      res.push(i);
    }
    return res;
  };

  handleDeleteButton = (h2, h3, id) => {
    if (!this.state.lockInDelete.show) {
      if (id) {
        this.setState({
          lockInDelete: { show: true, h2: h2, h3: h3, articleId: id },
        });
      } else {
        this.setState({
          lockInDelete: { show: true, h2: h2, h3: "", articleId: h3 },
        });
      }
    }
  };
  handleExitLockIn = () => {
    this.setState({
      lockInDelete: { show: false, h2: "", h3: "", articleId: "" },
    });
  };
  handleDeleteDocument = () => {
    const articleId = this.state.lockInDelete.articleId;

    if (this.state.renderSection.id === "ads") {
      deleteDocument("ads", articleId);
      deleteFile("ad-images", articleId);
      let ads = [...this.state.ads];
      ads = ads.filter((a) => a.id !== articleId);
      this.setState({ ads });
    } else {
      deleteDocument("articles", articleId);
      deleteFile("article-images", articleId);
      let articles = [...this.state.articles];
      articles = articles.filter((a) => a.articleId !== articleId);
      this.setState({ articles });
    }

    this.handleExitLockIn();
  };
  handleLogOut = async () => {
    await signOut(auth);
  };
  handleSectionChange = (newSection) => {
    if (newSection === "articles") {
      this.setState({
        renderSection: {
          id: "articles",
          mainButtonText: "neuer Artikel",
          mainButtonLink: "/edit/neuer-artikel",
        },
      });
    } else if (newSection === "ads") {
      this.setState({
        renderSection: {
          id: "ads",
          mainButtonText: "neues Inserat",
          mainButtonLink: "/edit/inserate/neu",
        },
      });
    }
  };

  render() {
    const { articles, ads, lockInDelete } = this.state;
    const { id, mainButtonText, mainButtonLink } = this.state.renderSection;

    if (!articles[0]) {
      return null;
    }

    return (
      <div>
        {lockInDelete.show && (
          <LockInDelete
            onExitLockIn={this.handleExitLockIn}
            onDeleteFinally={this.handleDeleteDocument}
            h2={lockInDelete.h2}
            h3={lockInDelete.h3}
          />
        )}
        <div className="log-out">
          <button className="btn btn-secondary" onClick={this.handleLogOut}>
            Ausloggen
          </button>
        </div>

        <h1 className="document-title">Links Back-End</h1>
        {mainButtonText && (
          <div className="btn-container">
            <Link className="add-article-container" to={mainButtonLink}>
              <button className="add-article-btn btn btn-primary">
                {mainButtonText}+
              </button>
            </Link>
          </div>
        )}

        <div className="ad-container">
          <div
            className="btn-group"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              type="radio"
              className="btn-check"
              name="sectionradio"
              id="articles-radio"
              autoComplete="off"
              checked={id === "articles"}
              onChange={() => this.handleSectionChange("articles")}
            />
            <label
              className="btn btn-outline-primary section-btn"
              htmlFor="articles-radio"
            >
              Artikel
            </label>

            <input
              type="radio"
              className="btn-check"
              name="sectionradio"
              id="podcast-radio"
              autoComplete="off"
              checked={id === "ads"}
              onChange={() => this.handleSectionChange("ads")}
            />
            <label
              className="btn btn-outline-primary section-btn"
              htmlFor="podcast-radio"
            >
              Inserate
            </label>
          </div>
        </div>

        {id === "articles" &&
          articles.map((article) => {
            return (
              <Card
                article={article}
                onDelete={this.handleDeleteButton}
                key={article.id}
              />
            );
          })}

        {id === "ads" &&
          ads.map((ad) => {
            return (
              <Card ad={ad} onDelete={this.handleDeleteButton} key={ad.id} />
            );
          })}
      </div>
    );
  }
}

export default Dashboard;
