import React from "react";
import "./header.css";

import kantonSg from "../../../images/kanton_sg.svg";
import linksLogo from "../../../images/links-logo.svg";

const Mobile = () => {
  return (
    <header>
      <div className="header-triangle-mobile">
        <img className="sg-logo-mobile" src={kantonSg} alt="" />
      </div>

      <a className="logo-link-mobile" href="/">
        <h1>
          <img className="links-logo-mobile" src={linksLogo} alt="Links" />
        </h1>
      </a>
    </header>
  );
};

export default Mobile;
